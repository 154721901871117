export interface APIResponse {
    ResponseType: string
    StatusMsg: string
    data: any
    success: boolean
}

export const APIStatus  = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    DATA_NOT_FOUND: 'DATA_NOT_FOUND'
}

export enum APIEnv {
    NODE_BOOKSTORE,
    REDIS,
    DOT_NET
}