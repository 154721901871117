import ApiService from "@Services/ApiService";
import {APIEnv} from "@Services/baseInterfaces";

export async function getData(endPoint: string, queryParams: object = {}, urlParam: any, APIEnvironment: APIEnv) {
    return await ApiService.fetchData({
        url: urlParam ? endPoint + "/" + urlParam : endPoint,
        method: "get",
        params: queryParams
    });
}
export async function postData(endPoint: string, data: any, APIEnvironment: APIEnv, method: string = 'post', customHeaders?: any) {

    return await ApiService.fetchData({
        url:  endPoint ,
        method: method,
        data: data,
        customHeaders: customHeaders
    }, APIEnvironment);
}