// import toast from "react-hot-toast";
import {APIEnv, APIResponse} from "@Services/baseInterfaces";
import {NodeBookStoreBaseService} from "@Services/base-services/NodeBookstoreBaseService";
import {DotNetBaseService} from "@Services/base-services/DotNetBaseService";
import {NodeDashboardBaseService} from "@Services/base-services/NodeDashboardBaseService";
import { RedisBaseService } from "./base-services/RedisBaseService";

const ApiService = {
    fetchData(param:any , ENV: APIEnv = APIEnv.DOT_NET) {
        if (ENV === APIEnv.DOT_NET) {
            return new Promise<APIResponse>((resolve, reject) => {
                DotNetBaseService(param).then((response: { data: APIResponse | PromiseLike<APIResponse>; }) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }
        if (ENV === APIEnv.REDIS) {
            return new Promise<APIResponse>((resolve, reject) => {

                RedisBaseService(param).then((response: { data: APIResponse | PromiseLike<APIResponse>; }) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }    

    }
}

export default ApiService