import jwt_decode from 'jwt-decode';
import jwt_encode from 'jwt-encode';
import DOMPurify from 'dompurify'
import { LocalStorageDataModel } from '@Constants/local-storage-data-model';
import { postData } from '@Src/services/global.service';
import { APIEnv, APIStatus } from '@Src/services/baseInterfaces';
import { HOME_API_CONSTANTS } from '@Constants/api-constants/home/home.constant';
import CryptoJS from "crypto-js";


export const encodeString = (value: any) => {
    return jwt_encode(value, process.env.JWT_ENCODE_SECRECT || "");
}

export const decodeString = (value: string) => {
    return jwt_decode(value);
}


export const setDataOnLocalStorage = (key: string, value: string) => {
    return localStorage.setItem(key, value);
}

export const getDataFromLocalStorage = (key: string) => {
    var value = localStorage.getItem(key);
    if (value === null) {
        return "";
    }
    return value;
}

export const removeDataFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
    return;
}

export const isMobile = () => {
    let mediaQueryObj = window.matchMedia("(max-width: 950px)");
    return mediaQueryObj.matches;
}

export const groupByAlphabet = (rawData: Array<any>):Array<any> => {
        return rawData?.reduce((r, e) => {
            // get first letter of name of current element
            let group = e?.name[0]?.toString()?.toUpperCase();
            // if there is no property in accumulator with this letter create it
            if(!r[group]) r[group] = {group, children: [e]}
            // if there is push current element to children array for that letter
            else r[group]?.children?.push(e);
            // return accumulator
            return r;
        }, {})

}


export const checkValidFileExtensions = (inputFileArray: any[], allowedFileExtensions: string[]):boolean => {
    let isValid = false;
    for(let i=0;i<allowedFileExtensions.length;i++){
        const splitArray=inputFileArray[0].name?.split(".")
         if(splitArray[splitArray.length-1]?.toString()?.toLowerCase()==allowedFileExtensions[i]?.toString()?.toLocaleLowerCase()){
            isValid=true
        }
    }
    return isValid;

}

export const customCheckValidFileSize = (inputFileArray: any[],MAX_FILE_SIZE:number): boolean => {
    let isValid = true;
    for(let i=0;i<inputFileArray.length;i++){
        const fileSizeKiloBytes = inputFileArray[i].size;
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            isValid=false;
        }
    }
    return isValid;
}

export function scrollToElement(elementId: string, offset: number = 0) {
    const element = document.getElementById(elementId);
    if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }
}

const sanitizedData = (data: any) => ({
    __html: DOMPurify.sanitize(data)
})

export const  decrypt = (ciphertext: string) =>{
    let key:any = process.env.REACT_APP_SCCRET_KEY_MACID;
    const secretKey = (key).replace(/KEYGEN/g, btoa('KEYGEN'));
    var bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }

export const getMachineId = async () => {
    if (getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW)) {
        return Promise.resolve(true);
    } else {	
    removeDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
    return await postData(HOME_API_CONSTANTS.GET_MACHINE_ID, {}, APIEnv.REDIS, 'get').then((res: any) => {
        if (res?.ResponseType === APIStatus.SUCCESS) {
            if (res.Data) {
                let decryptedID:any= decrypt(res.Data);
                if(decryptedID){
                    decryptedID = JSON.parse(decryptedID)
                    if(decryptedID && decryptedID.machineId){
                     setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW, decryptedID.machineId);
                // kept old machine id too , as it can affect the otther apps like bookstore and students
                      setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, decryptedID.machineId);
                      return Promise.resolve(true);
                    }else{
                        return Promise.resolve(false);	
                    }
                }else{
                    return Promise.resolve(false);	
                }
            }
        }
    })
  }
  }